import React, { createContext, useMemo, useState, useEffect } from "react";
import { setAuthData } from "../utils/LocalStorageManager";
import Urls from "../urls/Urls";

const AppContext = createContext();
const { Provider } = AppContext;

const AppProvider = ({ children, authData }) => {
  // eslint-disable-next-line
  const [authInfo, setAuthInfo] = useState(authData);
  const [isLoading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [successMsg, setSuccessMsg] = useState(null);
  const [modalData, setModalData] = useState({
    modalOpen: false,
    modalType: null,
    modalObject: null,
  });
  const [authenticate, setAuthenticate] = useState(authData != null);

  const logoutProvider = async (e) => {
    //e.preventDefault();
    await setAuthData("authOPAdminInfo", null);
    setAuthenticate(false);
    window.location.replace(Urls.base_login);
  };

  /*  useEffect(() => {
    let time = 300000;
    let timeOutTimer = setTimeout(logoutProvider, time);
    const checkInactivity = () => {
      window.onclick = () => checkInactivity();
      window.onkeypress = () => checkInactivity();
      window.addEventListener("mousemove", checkInactivity);
      clearTimeout(timeOutTimer);
      timeOutTimer = setTimeout(logoutProvider, time);
    };
    checkInactivity();
  }, []); */

  const resetErrorMsg = () => {
    setErrorMsg(null);
  };

  const resetSuccessMsg = () => {
    setSuccessMsg(null);
  };

  const resetAllMsg = () => {
    setErrorMsg(null);
    setSuccessMsg(null);
  };

  const values = useMemo(
    () => ({
      authInfo,
      authenticate,
      isLoading,
      errorMsg,
      successMsg,
      modalData,
      logoutProvider,
      setLoading,
      resetErrorMsg,
      setErrorMsg,
      resetSuccessMsg,
      setSuccessMsg,
      resetAllMsg,
      setModalData,
    }),
    // eslint-disable-next-line
    [authInfo, authenticate, isLoading, errorMsg, successMsg, modalData]
  );

  return <Provider value={values}>{children}</Provider>;
};

export { AppContext, AppProvider };
