import { CONSTANT } from "./Constant";
export const URL = {
  /** HOME PATH */
  LOGIN_TWOFACTORCODE_REQUEST:
    CONSTANT.DISPATCHER_USER_PREFIX + "/user/login/twofactor",
  LOGIN_REQUEST: CONSTANT.DISPATCHER_USER_PREFIX + "/user/autenticate",
  LOGIN_RECOVERY_PASS_REQUEST:
    CONSTANT.DISPATCHER_USER_PREFIX + "/user/recoverPasswordAdmin",
  LOGIN_CHANGE_PASS_REQUEST:
    CONSTANT.DISPATCHER_USER_PREFIX + "/user/changePassword",
  LOGIN_ACTIVE_USER_REQUEST: CONSTANT.DISPATCHER_USER_PREFIX + "/user/activate",
  SESSIONVALIDATE_REQUEST:
    CONSTANT.DISPATCHER_USER_PREFIX + "/user/validatesession",

  /** maintenance urls */
  REQUEST_CERTCATEGORY_LIST: (page, size) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/certificate-categories/${page}/${size}`,
  REQUEST_CERTCATEGORY: (id) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/certificate-categories/${id ? id : ""}`,

  REQUEST_CERTIFICATE_LIST: (page, size) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/certificates/${page}/${size}`,
  REQUEST_CERTIFICATE: (id) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/certificates/${id ? id : ""}`,

  /** users urls */
  REQUEST_USER_LIST: (page, size) =>
    CONSTANT.DISPATCHER_USER_PREFIX + `/user/${page}/${size}`,
  REQUEST_USER_CREATE_ADMIN:
    CONSTANT.DISPATCHER_USER_PREFIX + `/user/createAdmin`,
  REQUEST_USER: (id) =>
    CONSTANT.DISPATCHER_USER_PREFIX + `/user/${id ? id : ""}`,
  REQUEST_ACTIVATE_USER: (code, username) =>
    CONSTANT.DISPATCHER_USER_PREFIX +
    `/user/activate?code=${code}&username=${username}`,

  /** role urls */
  REQUEST_ROLE_LISTALL: CONSTANT.DISPATCHER_USER_PREFIX + `/role/`,
  REQUEST_ROLE_LIST: (page, size) =>
    CONSTANT.DISPATCHER_USER_PREFIX + `/role/${page}/${size}`,
  REQUEST_ROLE_CREATE: CONSTANT.DISPATCHER_USER_PREFIX + `/role/create`,
  REQUEST_ROLE: (id) =>
    CONSTANT.DISPATCHER_USER_PREFIX + `/role/${id ? id : ""}`,

  /** email urls */
  REQUEST_EMAILROLE_LIST: (page, size, roleID) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/profile-email/${page}/${size}/role/${roleID}`,
  REQUEST_EMAILROLE: (id) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/profile-email/${id ? id : ""}`,

  REQUEST_RECORD_LIST: (page, size) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/expedients/${page}/${size}`,
  REQUEST_RECORD: (id) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/expedients/${id ? id : ""}`,
  REQUEST_STORES_RECORD: (id, page, size) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/expedients/stores/${id ? id : ""}/${page}/${size}`,

  /** authority */
  REQUEST_AUTHORITY_LIST: (page, size) =>
    CONSTANT.DISPATCHER_USER_PREFIX + `/authorities/${page}/${size}`,
  REQUEST_AUTHORITY_BY_ROLE_LIST: (roleId, page, size) =>
    CONSTANT.DISPATCHER_USER_PREFIX + `/authorities/${roleId}/${page}/${size}`,
  REQUEST_AUTHORITY: (id) =>
    CONSTANT.DISPATCHER_USER_PREFIX + `/authorities/${id ? id : ""}`,

  /** authority for role */
  REQUEST_AUTHORITYROLES_LIST: (roleId, page, size) =>
    CONSTANT.DISPATCHER_USER_PREFIX +
    `/authorities-roles/${roleId}/${page}/${size}`,
  REQUEST_AUTHORITYROLES: (id) =>
    CONSTANT.DISPATCHER_USER_PREFIX + `/authorities-roles/${id ? id : ""}`,

  /** document */
  REQUEST_DOCUMENT_LIST: (page, size) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/documents/${page}/${size}`,
  REQUEST_DOCUMENT: (id) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/documents/${id ? id : ""}`,

  REQUEST_OPERATION_LIST: (page, size) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/operation-types/${page}/${size}`,
  REQUEST_OPERATION: (id) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/operation-types/${id ? id : ""}`,
  REQUEST_OPERATION_CREATE:
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/operation-types/`,

  /** DocumentByTypeOp */
  //REQUEST_DOCUMENTBYTYPEOP_LIST: () => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/operation-types-document/`,
  REQUEST_DOCUMENTBYTYPEOP_LIST: (page, operationType, size) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/operation-types-document/operation-type/${operationType}/${page}/${size}`,
  REQUEST_DOCUMENTBYTYPEOP: (id) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/operation-types-document/${id ? id : ""}`,
  ALL_OPER_TYPE:
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/operation-types/0/100`,

  /**Dashboard */
  REQUEST_RECORD_STAGE:
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/dashboard/expedient-status`,
  REQUEST_RECORD_PERSON_TYPE:
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/dashboard/expedient-person-type`,
  REQUEST_RECORD_REGISTERED:
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/dashboard/registered-expedient`,
  REQUEST_RECORD_CURRENCY:
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/dashboard/expedient-currency`,
  REQUEST_RECORD_BY_OPERATION:
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/dashboard/expedient-operation-type`,
  REQUEST_RECORD_COMPARATION:
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/dashboard/expedient-comparation`,
};
