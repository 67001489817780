import React, { lazy } from "react";
import {
  Route,
  defer,
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
} from "react-router-dom";
import { AuthLayout } from "../components/template/AuthLayout";
import { getAuthData } from "../utils/LocalStorageManager";

/** SESSION */
const Session = lazy(() => import("../pages/session/Session"));

/** Template */
const BoardLayout = lazy(() => import("../components/template/BoardLayout"));

/** Board home page */
const Home = lazy(() => import("../pages/board/home/Home"));

/** Board config pages */
const User = lazy(() => import("../pages/board/config/user/User"));
const Role = lazy(() => import("../pages/board/config/role/Role"));
const Authority = lazy(() =>
  import("../pages/board/config/authority/Authority")
);
const AuthoritiesXRole = lazy(() =>
  import("../pages/board/config/authoritiesxrole/AuthoritiesXRole")
);
const EmailRole = lazy(() =>
  import("../pages/board/config/emailRole/EmailRole")
);
const Record = lazy(() => import("../pages/board/record/Record"));

const Document = lazy(() =>
  import("../pages/board/maintenance/document/Document")
);
const OperationType = lazy(() =>
  import("../pages/board/maintenance/operationtype/OperationType")
);
const DocumentByTypeOp = lazy(() =>
  import("../pages/board/maintenance/documentByTypeOp/DocumentByTypeOp")
);

const NotFound = lazy(() => import("../pages/utils/NotFound"));
const Reports = lazy(() => import("../pages/board/Reports"));

export const appRoutes = createBrowserRouter(
  createRoutesFromElements(
    <Route
      element={<AuthLayout />}
      loader={async () => defer({ authPromise: getAuthData() })}
    >
      <Route exact path="/session" element={<Session />} />
      <Route path="/board" element={<BoardLayout />}>
        <Route exact path="home" element={<Home />} />
        <Route exact path="reports" element={<Reports />} />
        <Route exact path="user" element={<User />} />
        <Route exact path="role" element={<Role />} />
        <Route exact path="authority" element={<Authority />} />
        <Route exact path="document" element={<Document />} />
        <Route exact path="operationtype" element={<OperationType />} />
        <Route exact path="documentbytypeop" element={<DocumentByTypeOp />} />
        <Route
          exact
          path="authoritiesxrole/:roleId"
          element={<AuthoritiesXRole />}
        />
        <Route exact path="emailrole" element={<EmailRole />} />
        <Route exact path="record" element={<Record />} />
      </Route>
      <Route exact path="/notfound" element={<NotFound />} />
      <Route path="/" exact element={<Navigate to="/board/home" replace />} />
      <Route path="*" element={<Navigate to="/notfound" replace />} />
    </Route>
  )
);
